import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import {  useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
//
import TableContainer from "../../../components/Common/TableContainer";

import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import {
  SERVICE_TRANSMISSION_METHOD_OPTIONS,
  TYPE_SCRIPT_OPTIONS,
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
} from "helpers/constans";
import CardSummary from "../Components/CardSummary";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import { IPagination } from "api/types/general";
import iconMemo from "../../../assets/images/media/memo.svg";
import iconHome from "../../../assets/images/media/home.svg";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import MediaModal from "./ModalMedia";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const location = useLocation();
  const TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG = TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS?.map(
    (item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })
  );

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("All Type"),
    value: "",
  });

  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState<any>();
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [open, setOpen] = useState(0);
  const defaultParams = {
    page: 1,
    page_size: 10,
  };
  const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [textMemo, setTextMemo] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<any>();

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      search: keyword,
      service_type: formatSearch?.value,
    });
  };

  useEffect(() => {
    searchData();
  }, [formatSearch]);

  const onCloseClick = () => {
    setOpen(0);
    setTextMemo("");
  };

  const handleSubmitMemo = async () => {
    setLoading(true);
    if (!open) {
      return;
    }
    try {
      const [err, result] = await catchAsync(MediaApi.updateNote({ id: open, note: textMemo }));

      if (result?.statusCode === 200) {
        toast("success", CONFIG_OPTION_TOAST_NORMAL);
        onCloseClick();
        paging();
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setLoading(false);
    }
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("media.STT"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <span>
              {pagination.total - pagination.page_size * (pagination.page - 1) - cell?.row?.index}
            </span>
          </div>
        ),
      },
      {
        Header: t("media.Name"),
        accessor: "name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <p className='m-0' onClick={() => setMedia(cell?.row?.original)}>
              <u>{cell?.value}</u>
            </p>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "domain",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <a href={cell?.value} target='_blank'>
              <img src={iconHome} alt='iconHome' />
            </a>
          </div>
        ),
      },
      {
        Header: t("media.ServicesUsed"),
        accessor: "services",
        thClass: "text-center",
        Cell: (cell: any) => {
          let tagName: string = "";
          for (let item of cell?.value) {
            tagName += `${
              item
                ? `<div class='tag-service bg-${item?.service_name}'  >${item?.service_name} </div>`
                : ""
            }`;
          }
          return <div dangerouslySetInnerHTML={{ __html: tagName }} />;
        },
      },
      {
        Header: t("media.Script"),
        accessor: "srcipt_status",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <span className='text-center'>
              {t(
                TYPE_SCRIPT_OPTIONS.find((i) => i.value === cell?.value?.script_type)?.label || ""
              )}
            </span>
          </div>
        ),
      },
      {
        Header: t("media.NewsHubTransmissionMethod"),
        accessor: "newshub_transmission_method",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <span className='text-center'>
              {t(
                SERVICE_TRANSMISSION_METHOD_OPTIONS.find((i) => i.value === cell?.value)?.label ||
                  "-"
              )}
            </span>
          </div>
        ),
      },
      {
        Header: t("media.DailyAveragePV"),
        accessor: "daily_pv",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t("media.MonthlyAveragePV"),
        accessor: "monthly_pv",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <span>{cell?.value}</span>
          </div>
        ),
      },
      {
        Header: t("media.Memo"),
        accessor: "id",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            <img
              onClick={() => {
                setOpen(cell?.value);
                setTextMemo(cell?.row?.original?.note);
              }}
              className='cursor-pointer'
              src={iconMemo}
              alt='iconMemo'
            />
          </div>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const paging = async () => {
    setLoading(true);
    try {
      const [err, result] = await catchAsync(MediaApi.getMedia(queryParams));

      if (err) {
        console.log(err);
        return;
      }
      const { items, pagination } = result?.data;
      setRows(items);
      setPagination(pagination);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("All Type"),
      value: "",
    });
  };

  const getSummaries = async () => {
    setLoadingSummaries(true);
    try {
      const [err, result] = await catchAsync(MediaApi.getSummaries());

      if (err) {
        console.log(err);
        return;
      }
      setSummaries(result?.data);

      // const { items, pagination } = result?.data;
      // setRows(items);
      // setPagination(pagination);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  useEffect(() => {
    getSummaries();
  }, []);

  useEffect(() => {
    console.log("media", media);
  }, [media]);

  useEffect(() => {
    document.title = `${t("Media")} - ${t("Media")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          {/* <BreadCrumb title={t("media.Management")} pageTitle={t("Media")} /> */}
          <Row>
            {
              // isHavePermissionRole(
              //   ROLES_FOR_APP.MEDIA_LIST,
              //   userPermissions
              // )
              true && (
                <Col lg={12}>
                  <Card id='leadsList'>
                    <CardHeader className='border-0'>
                      <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                        <CardSummary
                          title='media.FullMedium'
                          value={summaries?.total || 0}
                          isLoading={loadingSummaries}
                        />

                        <CardSummary
                          title='media.CMSHubUsageMedia'
                          value={summaries?.total_cmshub || 0}
                          isLoading={loadingSummaries}
                        />

                        <CardSummary
                          title='media.MediaUsedInNewsHub'
                          value={summaries?.total_newshub || 0}
                          isLoading={loadingSummaries}
                        />

                        <CardSummary
                          title='media.MediaUsedByPRHub'
                          value={summaries?.total_prhub || 0}
                          isLoading={loadingSummaries}
                        />
                      </div>
                      <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                        <div className='w-100'>
                          <div className='align-items-end'>
                            <Row className='g-4 align-items-center mt-0 mt-md-2'>
                              {/* <Col sm={12} md={4} lg={2} className="mt-2">
                                <LabelWrapper
                                  label={t("Type")}
                                  isShow={!!formatSearch?.value}
                                >
                                  <DropdownOption
                                    name="type"
                                    dataList={
                                      TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG ||
                                      []
                                    }
                                    className="search-filter-category-type"
                                    classNamePrefix="name-prefix"
                                    initialValue={formatSearch || null}
                                    onChangeSelect={(e: any) =>
                                      setFormatSearch(e)
                                    }
                                    isHasOptionAll={true}
                                    optionAll={{
                                      label: t("All Type"),
                                      value: "",
                                    }}
                                  />
                                </LabelWrapper>
                              </Col> */}
                              <Col sm={12} md={12} lg={6} className='mt-3 mt-md-2'>
                                <LabelWrapper label={t("Media Name")} isShow={!!keyword}>
                                  <Input
                                    type='text'
                                    className='form-control search'
                                    placeholder={`${t("media.PlaceholderSearch")}...`}
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        searchData();
                                      }
                                    }}
                                  />
                                </LabelWrapper>
                              </Col>
                              <Col
                                sm={12}
                                md={12}
                                lg={3}
                                className='hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2'
                              >
                                <div>
                                  <button
                                    type='button'
                                    className='btn btn-primary me-1'
                                    onClick={searchData}
                                    disabled={loading}
                                  >
                                    <i className='ri-search-line align-bottom me-1'></i>{" "}
                                    {t("Button Search")}
                                  </button>
                                  <button
                                    type='button'
                                    className='btn btn-secondary fs-14'
                                    onClick={resetData}
                                  >
                                    <i className='ri-refresh-line align-bottom me-1'></i>{" "}
                                    {t("Button Reset")}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>

                      <Nav
                        className='nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0'
                        role='tablist'
                      >
                        {TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS_LANG.map((item, index) => (
                          <NavItem key={index}>
                            <NavLink
                              className={`cursor-pointer ${classnames({
                                active: formatSearch?.value === item?.value,
                              })}`}
                              onClick={() => {
                                setFormatSearch(item);
                              }}
                            >
                              {t(item?.label)}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </CardHeader>
                    <CardBody className='pt-3'>
                      <div>
                        <TableContainer
                          className='custom-header-css'
                          divClass='table-responsive table-card'
                          tableClass='align-middle'
                          theadClass='table-light'
                          columns={columns}
                          data={rows || []}
                          customPageSize={pagination?.page_size}
                          customPageIndex={pagination?.page - 1}
                          totalRecords={pagination?.total}
                          customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                          handleChangePage={handleChangePage}
                          manualSorting={true}
                          isLoading={loading}
                        />
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </CardBody>
                  </Card>
                </Col>
              )
            }
          </Row>
        </Container>
      </div>
      <Modal isOpen={!!open} centered={true} scrollable={false}>
        <ModalHeader className='justify-content-center'>{t("media.Memo")}</ModalHeader>
        <ModalBody className=''>
          <div>
            <textarea
              className='form-control'
              id='textMemo'
              rows={5}
              placeholder={t("media.PlaceholderMemo")}
              value={textMemo}
              maxLength={500}
              onChange={(e: any) => setTextMemo(e.target.value)}
            />

            <div className='d-flex justify-content-end'>
              <p>{textMemo?.length}/500</p>
            </div>

            <div className='hstack gap-2 mt-3 justify-content-end'>
              <button
                className='btn btn-soft-secondary fs-14'
                type='button'
                color='light'
                onClick={() => onCloseClick && onCloseClick()}
              >
                {t("media.Cancel")}
              </button>
              <button type='button' className='btn btn-primary fs-14' onClick={handleSubmitMemo}>
                {false ? <Spinner size='sm me-2'></Spinner> : null}
                {t("media.Registration")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <MediaModal media={media} onClose={() => setMedia(false)} onRefresh={() => paging()}/>
    </React.Fragment>
  );
};

export default MediaList;
