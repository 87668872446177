import { APIClient } from "./axiosClient";
import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/cmshub";
const cmsHubMembershipApi = {
    cmshubMembership(params: QueryParams) {
        return api.get(`${path}/payments`, params);
    },
    cmshubMembershipSumary(params: QueryParams) {
        return api.get(`${path}/payments/summary`, params);
    },
    cmshubCompanyPlan(params: QueryParams) {
        return api.get(`${path}/company-plans`, params);
    },
    cmshubSummaryCompanyPlan(params: QueryParams) {
        return api.get(`${path}/company-plans/summary`, params);
    },
    cmshubCompanyPlanHistory(company_id: number) {
        return api.get(`${path}/company-plans/${company_id}/histories`);
    },
    cmshubCompanyExceededUsageCount() {
        return api.get(`${path}/company-plans/exceeded-usages/count`);
    },
    cmshubCompanyExceededUsages(params: QueryParams) {
        return api.get(`${path}/company-plans/exceeded-usages`,params);
    },
    getDetailCompany(id:number) {
        return api.get(`${path}/companies/${id}`);
    },
    deactivateMembership(company_id:number) {
        return api.post(`${path}/company-plans/${company_id}/deactivate`,{});
    },
    updateMembership(company_id:number, params: any) {
        return api.put(`${path}/company-plans/${company_id}/update`,params);
    },
};

export default cmsHubMembershipApi;
