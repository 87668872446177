import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
} from "reactstrap";

import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import {
  INQUIRY_SORT_BY,
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
  INQUIRY_STATUS,
  INQUIRY_STATUS_DETAIL_PAGE,
  SERVICE_TYPE_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync } from "helpers/general";
import { IPagination } from "api/types/general";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import classnames from "classnames";
import CardSummary from "pages/Media/Components/CardSummary";
import TableContainer from "components/Common/TableContainer";
import DatePickerCustom from "components/Common/DatePickerCustom";
import inquiryApi from "api/inquiryApi";
interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const InquiryHomePage = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  const location = useLocation();
  const [loadingReply, setLoadingReply] = useState<boolean>(false);
  const SERVICE_TYPE_OPTIONS_LANG = SERVICE_TYPE_OPTIONS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));
  const SORT_BY_OPTIONS = INQUIRY_SORT_BY?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const INQUIRY_STATUS_DETAIL_PAGE_OPTIONS = INQUIRY_STATUS_DETAIL_PAGE?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const [formatSearch, setFormatSearch] = useState<Option | null>({
    label: t("All Type"),
    value: "0",
  });

  const [sortBy, setSortBy] = useState<Option | null>({
    label: t("inquiry.Newest_First"),
    value: "newest",
  });

  const [statusDetailPage, setStatusDetailPage] = useState<Option | null>({
    label: t("inquiry.Awaiting_Reply"),
    value: "0",
  });

  const [loading, setLoading] = useState(false);
  const [inquiryDetail, setInquiryDetail] = useState<any>();
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    created_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    created_to: moment().format("YYYY-MM-DD"),
    sort_by: "",
    service_type: "0",
  };
  const { getQueryParams, clearQueryParams, setQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const [keyword, setKeyword] = useState<string>("");
  const [textReply, setTextReply] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [summaries, setSummaries] = useState<any>();
  const defaultRangeDate = [
    moment(queryParams?.created_from || "", "Y-MM-DD").toDate(),
    moment(queryParams?.created_to || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [isChangeKeyword, setIsChangeKeyword] = useState<boolean>(false);

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      page: 1,
      created_from: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      created_to: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      search: isChangeKeyword ? keyword : queryParams?.search,
      service_type: formatSearch?.value ? formatSearch?.value : queryParams?.service_type,
      sort_by: sortBy?.value ? sortBy?.value : queryParams?.sort_by,
    });
  };

  useEffect(() => {
    searchData();
  }, [formatSearch, sortBy]);

  const onCloseClick = () => {
    setInquiryDetail({});
  };

  const handleSubmitReply = async () => {
    setLoadingReply(true);
    if (!inquiryDetail?.id) {
      setLoadingReply(false);
      return;
    }
    try {
      const [err, result] = await catchAsync(
        inquiryApi.updateReplyHomePage({
          id: inquiryDetail?.id,
          body: {
            status: statusDetailPage?.value,
            reply: textReply,
          },
        })
      );

      if (result?.statusCode === 200) {
        toast("success", CONFIG_OPTION_TOAST_NORMAL);
        onCloseClick();
        paging();
        getSummaries();
        setTextReply('');
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setLoadingReply(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t("inquiry.STT"),
        thClass: "text-center",
        Cell: (cell: any) => (
          <div
            className='text-center'
            style={{
              width: "60px",
            }}
          >
            <span>
              {pagination.total - pagination.page_size * (pagination.page - 1) - cell?.row?.index}
            </span>
          </div>
        ),
      },
      {
        Header: t("inquiry.Service"),
        accessor: "service_type",
        thClass: "text-center",
        Cell: (cell: any) => {
          let tagName: string = "";
          const servicesKeyType = new Map(
            TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
          );
          tagName += `${`<div class='tag-service mx-auto bg-${servicesKeyType.get(
            cell?.value.toString()
          )}'  >${servicesKeyType.get(cell?.value.toString())} </div>`}`;
          return (
            <div
              className='mx-auto'
              style={{
                width: "80px",
              }}
              dangerouslySetInnerHTML={{ __html: tagName }}
            />
          );
        },
      },
      {
        Header: t("inquiry.company_name"),
        accessor: "company_name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.manager"),
        accessor: "contact_name",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.position"),
        accessor: "contact_position",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.email"),
        accessor: "email",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "100px",
            }}
          >
            {cell?.value ? cell?.value : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.detail"),
        accessor: "content",
        thClass: "text-center",
        Cell: (cell: any) => (
          <div className='w-100 text-center'>
            {cell?.value ? (
              <p
                onClick={() => {
                  setInquiryDetail(cell?.row?.original);
                  setTextReply(cell?.row?.original?.reply);
                  if (cell?.row?.original?.status == 2) {
                    setStatusDetailPage({
                      label: t("inquiry.Completed"),
                      value: "2",
                    });
                  }
                }}
                className='text-decoration-underline cursor-pointer mb-0'
                style={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "150px",
                }}
              >
                {cell?.value}
              </p>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        Header: t("inquiry.Status"),
        accessor: "status",
        thClass: "text-center",
        Cell: (cell: any) => {
          const statusOptions = new Map(INQUIRY_STATUS.map((i) => [i.value, i.label]));
          return (
            <p
              className='mb-0'
              style={{
                width: "100px",
              }}
            >
              {t(statusOptions.get(cell?.value.toString()) || "-")}
            </p>
          );
        },
      },
      {
        Header: t("inquiry.Completed_At"),
        accessor: "reply_at",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "150px",
            }}
          >
            {moment(cell.value).isValid() ? moment(cell.value).format("YYYY.MM.DD HH.mm.ss") : "-"}
          </p>
        ),
      },
      {
        Header: t("inquiry.CS_Manager"),
        accessor: "managed_by",
        thClass: "text-center",
        Cell: (cell: any) => (
          <p
            className='mb-0'
            style={{
              minWidth: "10px",
            }}
          >
            {cell?.value?.name || "-"}
          </p>
        ),
      },
    ],
    [i18n?.language, userPermissions, pagination]
  );

  const paging = async () => {
    setLoading(true);
    const requestBody = {
      ...queryParams,
      service_type: queryParams?.service_type || "0",
    };
    try {
      const [err, result] = await catchAsync(inquiryApi.getListHomePage(requestBody));

      if (err) {
        console.log(err);
        return;
      }
      const { items, pagination } = result?.data;
      setRows(items);
      setPagination(pagination);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setFormatSearch({
      label: t("All Type"),
      value: "0",
    });
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const getSummaries = async () => {
    setLoadingSummaries(true);
    try {
      const [err, result] = await catchAsync(
        inquiryApi.getSummariesHomePage({ service_type: "0" })
      );

      if (err) {
        console.log(err);
        return;
      }
      setSummaries(result?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSummaries(false);
    }
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
    // let params = { ...queryParams };
    // params.created_from = values[0] ? moment(values[0]).format("YYYY-MM-DD") : null;
    // params.created_to = values[1] ? moment(values[1]).format("YYYY-MM-DD") : null;
    // setQueryParams(params);
  };

  const renderService = (service_type: number) => {
    let tagName: string = "";
    const servicesKeyType = new Map(
      TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
    );
    tagName += `${`<div class='tag-service bg-${servicesKeyType.get(
      service_type.toString()
    )}'  >${servicesKeyType.get(service_type.toString())} </div>`}`;
    return (
      <div
        className='mx-auto'
        style={{
          width: "fit-content",
        }}
        dangerouslySetInnerHTML={{ __html: tagName }}
      />
    );
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location?.search]);

  useEffect(() => {
    getSummaries();
  }, []);

  useEffect(() => {
    document.title = `${t("Media")} - ${t("Media")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='d-flex align-items-center gap-2 mb-3'>
          <button
            onClick={() => navigate(-1)}
            type='button'
            className='btn btn-icon btn-topbar btn-ghost-secondary rounded-circle'
          >
            <i className={"bx bx-arrow-back fs-22"}></i>
          </button>
          <h4 className='mb-sm-0'>{t("inquiry.homepage_inquiry")}</h4>
        </div>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id='leadsList'>
                <CardHeader className='border-0'>
                  <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                    <CardSummary
                      title='inquiry.CMS_Hub_Waiting_for_response'
                      value={summaries?.CMS_HUB || 0}
                      isLoading={loadingSummaries}
                    />
                    <CardSummary
                      title='inquiry.PR_Hub_Waiting_for_response'
                      value={summaries?.PR_HUB || 0}
                      isLoading={loadingSummaries}
                    />
                  </div>
                  <div className='d-flex flex-column flex-md-row g-4 align-items-start align-items-lg-end mb-2'>
                    <div className='w-100'>
                      <div className='align-items-end'>
                        <Row>
                          <Col sm={12} md={12} lg={6} xl={6} className='mt-2 mt-md-0 mb-2 mb-md-0'>
                            <div
                              className='d-flex align-items-center date-picker-wrapper-custom'
                              style={{ gap: "40px" }}
                            >
                              <div className='fw-bold text-nowrap'>{t("inquiry.period")}</div>
                              <div style={{ width: "300px" }}>
                                <DatePickerCustom
                                  placeholder={`${t("A From Range")} - ${t("A To Range")}`}
                                  startDate={startDate || null}
                                  endDate={endDate || null}
                                  disabled={false}
                                  isClearable={true}
                                  onChangePicker={handleChangePicker}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className='g-4 align-items-center mt-0 mt-md-2'>
                          <Col sm={12} md={12} lg={4} className='mt-3 mt-md-2'>
                            <div className='d-flex align-items-center ' style={{ gap: "40px" }}>
                              <div className='fw-bold text-nowrap'>{t("inquiry.search")}</div>
                                <Input
                                  type='text'
                                  className='form-control search'
                                  placeholder={`${t("inquiry.PlaceholderSearch")}`}
                                  value={keyword}
                                  onChange={(e) =>{
                                    setIsChangeKeyword(true);
                                    setKeyword(e.target.value)
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      searchData();
                                    }
                                  }}
                                />
                            </div>
                          </Col>
                          <Col
                            sm={12}
                            md={12}
                            lg={3}
                            className='hstack gap-1 justify-content-sm-center justify-content-md-between mt-sm-4 mt-md-2'
                          >
                            <div>
                              <button
                                type='button'
                                className='btn btn-primary me-1'
                                onClick={searchData}
                                disabled={loading}
                              >
                                <i className='ri-search-line align-bottom me-1'></i>{" "}
                                {t("Button Search")}
                              </button>
                              <button
                                type='button'
                                className='btn btn-secondary fs-14'
                                onClick={resetData}
                              >
                                <i className='ri-refresh-line align-bottom me-1'></i>{" "}
                                {t("Button Reset")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between'>
                    <Nav
                      className='nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0'
                      role='tablist'
                    >
                      {SERVICE_TYPE_OPTIONS_LANG.map(
                        (item, index) =>
                          index !== 2 && (
                            <NavItem key={index}>
                              <NavLink
                                className={`cursor-pointer ${classnames({
                                  active: formatSearch?.value === item?.value,
                                })}`}
                                onClick={() => {
                                  setFormatSearch(item);
                                }}
                              >
                                {t(item?.label)}
                              </NavLink>
                            </NavItem>
                          )
                      )}
                    </Nav>
                    <LabelWrapper isShow={false} className='w-25'>
                      <DropdownOption
                        name='sort_by'
                        dataList={SORT_BY_OPTIONS || []}
                        className='search-filter-category-type'
                        classNamePrefix='name-prefix'
                        initialValue={sortBy || null}
                        onChangeSelect={(value: Option) => setSortBy(value)}
                        // isHasOptionAll={true}
                        // optionAll={{
                        //   label: t("inquiry.AllData"),
                        //   value: "",
                        // }}
                      />
                    </LabelWrapper>
                  </div>
                </CardHeader>
                <CardBody className='pt-3'>
                  <div>
                    <TableContainer
                      className='custom-header-css'
                      divClass='table-responsive table-card'
                      tableClass='align-middle text-center'
                      theadClass='table-light text-center'
                      columns={columns}
                      data={rows || []}
                      customPageSize={pagination?.page_size}
                      customPageIndex={pagination?.page - 1}
                      totalRecords={pagination?.total}
                      customPageCount={Math.ceil(pagination?.total / pagination?.page_size)}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      isLoading={loading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={!!inquiryDetail?.id}
        centered={true}
        scrollable={false}
        style={{ maxWidth: "1000px", width: "100%" }}
      >
        <ModalBody className=''>
          <div>
            <div className='d-relative'>
              <h5 className='text-center w-100 mb-4'>{t("inquiry.inquiry_detail")}</h5>
              <div
                className='position-absolute cursor-pointer'
                style={{
                  top: "12px",
                  right: "12px",
                }}
              >
                <button
                  onClick={() => setInquiryDetail(false)}
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                ></button>
              </div>
            </div>
            <div className='d-flex gap-4 align-items-center py-1'>
              <Label className='form-label mb-0'>{t("inquiry.Service")}</Label>
              <div
                className='mb-0'
                style={{
                  width: "fit-content",
                }}
              >
                {renderService(inquiryDetail?.service_type || "")}
              </div>
            </div>
            <div className='d-flex gap-5 py-1'>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.company_name")}</Label>
                <p className='mb-0'>{inquiryDetail?.company_name}</p>
              </div>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.manager")}</Label>
                <p className='mb-0'>{inquiryDetail?.contact_name}</p>
              </div>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.position")}</Label>
                <p className='mb-0'>{inquiryDetail?.contact_position}</p>
              </div>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.email")}</Label>
                <p className='mb-0'>{inquiryDetail?.email}</p>
              </div>
            </div>
            <div className='d-flex gap-5 py-1'>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.phoneNumber")}</Label>
                <p className='mb-0'>{inquiryDetail?.phone_number}</p>
              </div>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.Created_At")}</Label>
                <p className='mb-0'>
                  {moment(inquiryDetail?.reply_at).isValid()
                    ? moment(inquiryDetail?.reply_at).format("YYYY.MM.DD HH.mm.ss")
                    : "-"}
                </p>
              </div>
              <div className='d-flex gap-4 align-items-center'>
                <Label className='form-label mb-0'>{t("inquiry.Status")}</Label>
                <DropdownOption
                  name='status'
                  dataList={INQUIRY_STATUS_DETAIL_PAGE_OPTIONS || []}
                  className='search-filter-category-type'
                  classNamePrefix='name-prefix'
                  initialValue={statusDetailPage || null}
                  onChangeSelect={(value: Option) => setStatusDetailPage(value)}
                />
              </div>
            </div>
            <div className='mt-2'>
              <Label className='form-label'>{t("inquiry.detail")}</Label>
              <p>{inquiryDetail?.content}</p>
            </div>

            <div className=''>
              <Label className='form-label'>{t("inquiry.answer")}</Label>
              <textarea
                className='form-control'
                id='reply'
                rows={5}
                placeholder={t("inquiry.PlaceholderMemo")}
                value={textReply}
                onChange={(e: any) => setTextReply(e.target.value)}
              />
            </div>
            <div className='hstack gap-2 mt-3 justify-content-center'>
              <button
                type='submit'
                className='btn btn-primary fs-14 w-25'
                disabled={statusDetailPage?.value == "0" && !textReply}
                onClick={handleSubmitReply}
              >
                {loadingReply ? <Spinner size='sm'></Spinner> : null}
                {t("inquiry.btn.save")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default InquiryHomePage;
