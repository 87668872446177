import { IPagination } from "api/types/general";
import DatePickerCustom from "components/Common/DatePickerCustom";
import TableContainer from "components/Common/TableContainer";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync, formatWithKoreanAmPm } from "helpers/general";
import i18n from "i18n";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import MediaInfomation from "./MediaInfomation";
import cmsHubMembershipApi from "api/membershipApi";

const UsageExceeded = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const defaultParams = {
    page: 1,
    page_size: 10,
    start_date: moment().subtract(7, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
  };
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const defaultRangeDate = [
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ];
  const [dateSearch, setDateSearch] = useState<string[]>(defaultRangeDate);
  const [startDate, endDate] = dateSearch;
  const [keyword, setKeyword] = useState<string>("");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [modal, setModal] = useState({
    isOpen: false,
    id: 0,
  });
  // Column
  const columns = useMemo(() => {
    return [
      {
        Header: t("media.STT"),
        accessor: "id",
        sortable: false,
        Cell: ({ row }: any) => (
          <div>
            {pagination.total -
              pagination.page_size * (pagination.page - 1) -
              (row?.index || 0)}
          </div>
        ),
      },
      {
        Header: t("membership.media_name"),
        accessor: "company_name",
        sortable: false,
        Cell: ({row}: any) => {
          return (
            <div
              onClick={() => {
                setModal({
                  isOpen: true,
                  id: row?.original?.company_id,
                });
              }}
              style={{ textUnderlineOffset: "3px", textDecoration: "underline",cursor: "pointer" }}
            >
              {row?.original?.company_name}
            </div>
          );
        },
      },
      {
        Header: t("membership.membership"),
        accessor: "plan_name",
        sortable: false,
      },
      {
        Header: t("membership.usage_(Used/Total)"),
        accessor: "usage_(Used/Total)",
        sortable: false,
        Cell: ({row}: any) => {
          const { hdd, limit_hdd,daily_transfer,limit_daily_transfer,monthly_transfer,limit_monthly_transfer } = row?.original;
          return (
            <div className="d-flex align-items-center gap-4">
              <div className="d-flex align-items-center gap-3">
                <span className="fw-bold">HDD</span>
                <span>{hdd}GB / {limit_hdd}GB</span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <span className="fw-bold">
                  {t("membership.daily_transfer_volume")}
                </span>
                <span>{daily_transfer}GB / {limit_daily_transfer}GB</span>
              </div>
              <div className="d-flex align-items-center gap-3">
                <span className="fw-bold">
                  {t("membership.monthly_transfer_volume")}
                </span>
                <span>{monthly_transfer}GB / {limit_monthly_transfer}GB</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("membership.usage_overtime"),
        accessor: "overtime_usage_at",
        sortable: false,
        Cell: ({row}: any) => {
          return <div>
             {row?.original?.overtime_usage_at && <div>{formatWithKoreanAmPm(row?.original?.overtime_usage_at)}</div>}
            </div>
        }
      },
      {
        Header: t("membership.payment_timer"),
        accessor: "payment_timer",
        sortable: false,
      }
    ];
  }, [t, i18n?.language, pagination]);

  const paging = async () => {
    queryParams.start_date = startDate ? moment(startDate).format("YYYY-MM-DD") : null;
    queryParams.end_date = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
    const [err, result] = await catchAsync(
      cmsHubMembershipApi.cmshubCompanyExceededUsages(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  // Search
  const handleSearchCategory = () => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: 1,
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
      end_date:endDate ? moment(endDate).format("YYYY-MM-DD") : null
    });
  };

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      search: keyword,
      page: page + 1,
    });
  };

  const resetData = () => {
    clearQueryParams();
    setKeyword("");
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setKeyword(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex align-items-center gap-2 mb-3">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          >
            <i className={"bx bx-arrow-back fs-22"}></i>
          </button>
          <h4 className="mb-sm-0">{t("membership.usage_exceeded")}</h4>
        </div>
        <Card id="leadsList">
          <CardHeader className="border-0">
            <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-end mb-2 mt-2">
              <div className="w-100">
                <Row>
                  <Col
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mt-2 mt-md-0 mb-2 mb-md-0"
                  >
                    <div
                      className="d-flex align-items-center date-picker-wrapper-custom"
                      style={{ gap: "40px" }}
                    >
                      <div className="fw-bold text-nowrap">{t('membership.period')}</div>
                      <div style={{ width: "300px" }}>
                        <DatePickerCustom
                          placeholder={`${t("A From Range")} - ${t(
                            "A To Range"
                          )}`}
                          startDate={startDate || null}
                          endDate={endDate || null}
                          disabled={false}
                          isClearable={true}
                          onChangePicker={handleChangePicker}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="g-4 align-items-center mt-0">
                  <Col sm={12} md={12} lg={6} xl={6} className="mt-2 mt-md-3">
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "40px" }}
                    >
                      <div className="fw-bold text-nowrap">{t('membership.search')}</div>
                      <Input
                        type="text"
                        className="form-control search"
                        placeholder={`${t("cms_media.input_search")}...`}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearchCategory();
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    className="mt-3 mt-md-3 hstack gap-1 justify-content-sm-center justify-content-md-between"
                  >
                    <div className="d-flex gap-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={loading}
                        onClick={handleSearchCategory}
                      >
                        <i className="ri-search-line align-bottom me-1"></i>{" "}
                        {t("Button Search")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary fs-14"
                        onClick={resetData}
                      >
                        <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                        {t("Button Reset")}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </CardHeader>
          <CardBody className="pt-3">
            <div>
              <TableContainer
                columns={columns}
                data={rows || []}
                customPageSize={pagination?.page_size}
                customPageIndex={pagination?.page - 1}
                totalRecords={pagination?.total}
                customPageCount={Math.ceil(
                  pagination?.total / pagination?.page_size
                )}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="align-middle"
                theadClass="table-light align-middle"
                handleChangePage={handleChangePage}
                isLoading={loading}
              />
            </div>
            <ToastContainer closeButton={false} limit={1} />
          </CardBody>
        </Card>
      </Container>
      <MediaInfomation isOpen={modal.isOpen} id={modal.id} onClose={()=>setModal({isOpen:false,id:0})} />
    </div>
  );
};

export default UsageExceeded;
