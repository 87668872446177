import { useState } from "react";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import moment from "moment";
//
import { IPagination } from "api/types/general";
import DropdownOption from "components/Common/DropdownOption";
import { TYPE_SCRIPT_OPTIONS } from "helpers/constans";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import { CONFIG_OPTION_TOAST_ERROR, CONFIG_OPTION_TOAST_NORMAL } from "common/toast";
import { useTranslation } from "react-i18next";
import useQueryParams from "components/Hooks/useQueryParams";
import MemberInformation from "pages/Media/MemberInformation";

const defaultParams = {
  page: 1,
  page_size: 10,
};

function ModalMedia({ media, setMedia }: any) {
  const { t } = useTranslation();
  const [tabModal, setTabModal] = useState("1");
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);
  const [loading, setLoading] = useState(false);
  const { getQueryParams } = useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const paging = async () => {
    setLoading(true);
    try {
      const [err, result] = await catchAsync(MediaApi.getMedia(queryParams));

      if (err) {
        console.log(err);
        return;
      }
      const { items, pagination } = result?.data;
      setRows(items);
      setPagination(pagination);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={!!media} centered={true} scrollable={false} style={{ minWidth: 800 }}>
      <ModalHeader>
        <Nav
          className='nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0 gap-3'
          role='tablist'
        >
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "1",
              })}`}
              onClick={() => {
                setTabModal("1");
              }}
            >
              {t("media.MediaInformation")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "2",
              })}`}
              onClick={() => {
                setTabModal("2");
              }}
            >
              {t("media.MemberInformation")}
            </NavLink>
          </NavItem>
        </Nav>
      </ModalHeader>
      <ModalBody className=''>
        <div>
          <TabContent activeTab={tabModal} className='text-muted'>
            <TabPane tabId={"1"} id={"tab-1"} style={{ height: 550 }}>
              <Formik
                initialValues={{
                  company_name: media?.name || "",
                  name: media?.admin?.name || "",
                  media_category: media?.media_category || "",
                  domain: media?.domain || "",
                  phone_number: media?.admin?.phone_number || "",
                  script_type: media?.srcipt_status?.script_type,
                }}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const [err, result] = await catchAsync(
                      MediaApi.updateCompanies({
                        id: media?.id,
                        data: {
                          ...values,
                          phone_number: values?.phone_number + "",
                        },
                      })
                    );

                    if (result?.statusCode === 200) {
                      toast("success", CONFIG_OPTION_TOAST_NORMAL);
                      setMedia(false);
                      paging();
                    }
                    if (err) {
                      console.log(err);
                      toast(Object.values(err)?.[0] || err, CONFIG_OPTION_TOAST_ERROR);
                    }
                  } catch (error: any) {
                    toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, handleChange, handleSubmit, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='company_name' className='form-label'>
                            {t("media.Name")}
                          </Label>
                          <Input
                            name='company_name'
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            maxLength={50}
                            value={values.company_name}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='name' className='form-label'>
                            {t("media.Manager")}
                          </Label>
                          <Input
                            name='name'
                            type='text'
                            maxLength={50}
                            className='form-control'
                            onChange={handleChange}
                            value={values.name}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='media_category' className='form-label'>
                            {t("media.Classification")}
                          </Label>
                          <Input
                            name='media_category'
                            type='text'
                            className='form-control'
                            onChange={handleChange}
                            maxLength={50}
                            value={values.media_category}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label className='form-label'>{t("media.Email")}</Label>
                          <Input
                            type='text'
                            className='form-control'
                            disabled
                            value={media?.admin?.email || ""}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='domain' className='form-label'>
                            {t("media.DomainURL")}
                          </Label>
                          <Input
                            name='domain'
                            type='text'
                            maxLength={50}
                            className='form-control'
                            onChange={handleChange}
                            value={values.domain}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='phone_number' className='form-label'>
                            {t("media.PhoneNumber")}
                          </Label>
                          <Input
                            name='phone_number'
                            type='number'
                            className='form-control'
                            onChange={handleChange}
                            maxLength={50}
                            value={values.phone_number}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='media_category' className='form-label'>
                            {t("media.ServiceSubscriptionDate")}
                          </Label>
                          <div className='d-flex flex-column gap-2'>
                            {media?.services?.map((item: any, index: number) => (
                              <div key={index} className='d-flex align-items-center gap-2'>
                                <div className={`tag-service bg-${item?.service_name}`}>
                                  {item?.service_name}
                                </div>

                                <p className='m-0'>
                                  {moment(item?.created_at).format("YYYY.MM.DD")}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label className='form-label'>{t("media.ScriptStatus")}</Label>
                          <DropdownOption
                            disabled
                            name='service_type'
                            dataList={TYPE_SCRIPT_OPTIONS?.map((item: any) => ({
                              value: item?.value,
                              label: t(item?.label),
                            }))}
                            className='search-filter-category-type'
                            classNamePrefix='name-prefix'
                            initialValue={TYPE_SCRIPT_OPTIONS.map((item: any) => ({
                              value: item?.value,
                              label: t(item?.label),
                            }))?.find((i) => i.value === values?.script_type)}
                            onChangeSelect={(e: any) => {
                              if (e) {
                                setFieldValue("script_type", e?.value);
                              }
                            }}
                            labelTop={""}
                          />
                          {media?.srcipt_status?.updated_at ? (
                            <div className='d-flex text-primary'>
                              <p className='m-0'>
                                {moment(media?.srcipt_status?.updated_at).format(
                                  "YYYY.MM.DD a hh:mm"
                                )}
                              </p>
                              <p className='m-0'>_{media?.srcipt_status?.updated_by}</p>
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className='mb-3'>
                          <Label htmlFor='media_category' className='form-label'>
                            {t("media.CMSInformation")}
                          </Label>
                          <div>
                            <div className='d-flex align-items-center gap-2'>
                              <div className='inline-flex'>
                                <p className='m-0 fw-bold inline-table'>{t("media.Membership")}</p>
                                <p className='m-0 fw-bold inline-table'>{t("media.PaymentDate")}</p>
                                <p className='m-0 fw-bold inline-table'>
                                  {t("media.NextPaymentDate")}
                                </p>
                                <p className='m-0 fw-bold inline-table'>{t("media.Point")}</p>
                              </div>

                              <div>
                                <p className='m-0'>{media?.cms_information?.membership || "-"}</p>
                                <p className='m-0'>{media?.cms_information?.payment_date || "-"}</p>
                                <p className='m-0'>
                                  {media?.cms_information?.next_payment_date || "-"}
                                </p>
                                <p className='m-0'>{media?.cms_information?.point || 0}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className='hstack gap-2 mt-3 justify-content-center'>
                      <button
                        className='btn btn-primary mx-auto fs-14 w-25'
                        type='button'
                        color='light'
                        onClick={() => setMedia(false)}
                      >
                        {t("media.Check")}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={"2"} id={"tab-2"} style={{ height: 550 }}>
              <MemberInformation
                refresh={() => {
                  paging();
                  setMedia(false);
                  setTabModal("1");
                }}
                id={media?.id}
                onClose={() => {
                  setMedia(false);
                  setTabModal("1");
                }}
              />
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalMedia;
