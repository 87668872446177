import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//Import Icons
import { useRole } from "components/Hooks/UserHooks";
import FeatherIcon from "feather-icons-react";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ROUTES } from "routes/allRoutes";
import config from "config";
import { isSuperAdmin } from "utils";

const Navdata = () => {
  const history = useNavigate();
  const { t } = useTranslation();
  const { userPermissions } = useRole();

  const [menuActive, setMenuActive] = useState<string>("DASHBOARD");

  // Multi Level
  const [isLevel1, setIsLevel1] = useState<boolean>(false);

  const [isCurrentState, setIsCurrentState] = useState("Dashboard");

  function updateIconSidebar(e: any) {
    if (e && e.target && e.target.getAttribute("sub-items")) {
      const ul: any = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("sub-items");
        const getID = document.getElementById(id) as HTMLElement;
        if (getID) getID.classList.remove("show");
      });
    }
  }
  const redirectMessageHub = () => {
    const { message_hub_sso_redirect_url } = config;
    const accessToken: any = localStorage.getItem("access_token");

    if (message_hub_sso_redirect_url && accessToken) {
      return `${message_hub_sso_redirect_url}?sso_token_newshub=${accessToken}`;
    } else {
      return "/#";
    }
  };

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
  }, [history]);

  const menuItems: any = [
    // {
    //     label: "Menu",
    //     isHeader: true,
    // },
    {
      id: "dashboard",
      label: t("Dashboards"),
      icon: <FeatherIcon icon="home" className="icon-dual" />,
      link: ROUTES.DASHBOARD,
      click: function (e: any) {
        setMenuActive((prev) => "DASHBOARD");
        setIsCurrentState((prev) => "Dashboard");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "DASHBOARD",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.DASHBOARD_VIEW,
        userPermissions
      ),
      // subItems: []
    },
    {
      id: "multilevel",
      label: t("Keyword"),
      icon: <FeatherIcon icon="grid" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "KEYWORD");
        setIsCurrentState((prev) => "Keyword");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "KEYWORD",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.NAVER_KEYWORD_LIST,
          ROLES_FOR_APP.CAR_KEYWORD_LIST,
          ROLES_FOR_APP.EXCHANGE_RATE_LIST,
          ROLES_FOR_APP.STOCK_CATEGORY_LIST,
          ROLES_FOR_APP.STOCK_KEYWORD_LIST,
          ROLES_FOR_APP.SPORT_CATEGORY_LIST,
          ROLES_FOR_APP.SPORT_TEAM_LIST,
          ROLES_FOR_APP.SPORT_SCHEDULE_LIST,
          ROLES_FOR_APP.TREND_INDEX_CATEGORY_LIST,
          ROLES_FOR_APP.TREND_INDEX_LIST,
          ROLES_FOR_APP.COIN_LIST,
        ],
        userPermissions
      ),
      subItems: [
        // {
        //     id: "naver-category",
        //     label: t('Naver Category'),
        //     link: "/keyword/naver-category",
        //     // click: function (e: any) {
        //     //     e.preventDefault();
        //     //     // setIsLevel1(!isLevel1);
        //     // },
        //     // stateVariables: isLevel1,
        // },
        {
          id: "naver-keyword",
          label: t("Naver Keyword"),
          link: ROUTES.NAVER_KEYWORD,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.NAVER_KEYWORD_LIST,
            userPermissions
          ),
        },
        {
          id: "car-category",
          label: t("Car Category"),
          link: "/keyword/car-category",
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAR_CATEGORY_LIST,
            userPermissions
          ),
        },
        {
          id: "car-keyword",
          label: t("Car Keyword"),
          link: ROUTES.CAR_KEYWORD,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAR_KEYWORD_LIST,
            userPermissions
          ),
        },

        // {
        //     id: "movie-category",
        //     label: t('Movie Category'),
        //     link: "/keyword/movie-category",
        //     stateVariables: isLevel1,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.MOVIE_CATEGORY_LIST, userPermissions),
        // },
        // {
        //     id: "movie-keyword",
        //     label: t('Movie Keyword'),
        //     link: "/keyword/movie-keyword",
        //     stateVariables: isLevel1,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.MOVIE_KEYWORD_LIST, userPermissions),
        // },
        // {
        //     id: "exchange-rate-category",
        //     label: t('Exchange Rate Category'),
        //     link: "/keyword/exchange-rate-category",
        //     stateVariables: isLevel1,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.EXCHANGE_RATE_CATEGORY_LIST, userPermissions),
        // },
        {
          id: "exchange-rate",
          label: t("Exchange Rate"),
          link: ROUTES.EXCHANGE_RATE,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.EXCHANGE_RATE_LIST,
            userPermissions
          ),
        },
        {
          id: "coin",
          label: t("Coin"),
          link: ROUTES.COIN,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.COIN_LIST,
            userPermissions
          ),
        },
        {
          id: "stock-category",
          label: t("Stock Category"),
          link: ROUTES.STOCK_CATEGORY,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.STOCK_CATEGORY_LIST,
            userPermissions
          ),
        },
        {
          id: "stock",
          label: t("Stock"),
          link: ROUTES.STOCK,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.STOCK_KEYWORD_LIST,
            userPermissions
          ),
        },
        {
          id: "sport-category",
          label: t("Sport Category"),
          link: ROUTES.SPORT_CATEGORY,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.SPORT_CATEGORY_LIST,
            userPermissions
          ),
        },
        // {
        //     id: "sport",
        //     label: t("Sport"),
        //     link: ROUTES.SPORT,
        //     stateVariables: isLevel1,
        //     isShow: isHavePermissionRole(
        //         ROLES_FOR_APP.SPORT_TEAM_LIST,
        //         userPermissions
        //     ),
        // },
        {
          id: "sport-schedule",
          label: t("Sports Schedule"),
          link: ROUTES.SPORT_SCHEDULE,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.SPORT_SCHEDULE_LIST,
            userPermissions
          ),
        },
        {
          id: "lotto",
          label: t("Lotto Result"),
          link: ROUTES.LOTTO_SCHEDULE,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.LOTTO_SCHEDULE_LIST,
            userPermissions
          ),
        },
        {
          id: "trend-index-category",
          label: t("Trend Index Category"),
          link: ROUTES.TREND_INDEX_CATEGORY,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.TREND_INDEX_CATEGORY_LIST,
            userPermissions
          ),
        },
        {
          id: "trend-index",
          label: t("Trend Index"),
          link: ROUTES.TREND_INDEX,
          stateVariables: isLevel1,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.TREND_INDEX_LIST,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel4",
      label: t("media.Management"),
      icon: <FeatherIcon icon="command" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "MEDIA");
        setIsCurrentState((prev) => "Media");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "MEDIA",
      isShow: isHavePermissionRole(
        ROLES_FOR_APP.MEDIA_MANAGEMENT,
        userPermissions
    ),
      subItems: [
        {
          id: "media",
          label: t("media.List"),
          link: ROUTES.MEDIA,
          isShow: true,
          // isChildItem: true,
          // click: function (e: any) {
          //     e.preventDefault();
          //     setMenuActive((prev) => "MEDIA");
          //     setIsCurrentState((prev) => "Media");
          //     updateIconSidebar(e);
          // },
          stateVariables: true,
        },
        {
          id: "revenue",
          label: t("media.Revenue"),
          link: ROUTES.MEDIA_REVENUE,
          // isShow: isHavePermissionRole(
          //     ROLES_FOR_APP.NOTIFICATION_LIST,
          //     userPermissions
          // ),
          // isChildItem: true,
          isShow: true,
          // click: function (e: any) {
          //     e.preventDefault();
          //     setMenuActive((prev) => "MEDIA");
          //     setIsCurrentState((prev) => "Media");
          //     updateIconSidebar(e);
          // },
          // stateVariables: true,
        },
        // {
        //     id: "settlement",
        //     label: t('Settlement'),
        //     link: "/settlement",
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.SETTLEMENT_LIST, userPermissions),
        // },
      ],
    },
    {
      id: "multilevel2",
      label: t("Campaign"),
      icon: <FeatherIcon icon="codesandbox" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "CAMPAIGN");
        setIsCurrentState((prev) => "Campaign");
        updateIconSidebar(e);
      },
      stateVariables:
        menuActive === "CAMPAIGN" ||
        menuActive === "TREND_RANKING_NEWS" ||
        menuActive === "KEYWORD_ANALYZING__NEWS",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.CAMPAIGN_CREATE,
          ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT,
          ROLES_FOR_APP.CAMPAIGN_LIST,
          ROLES_FOR_APP.NEWS_LIST,
        ],
        userPermissions
      ),
      subItems: [
        {
          id: "level1.1",
          label: t("Trend Ranking News"),
          link: "/#",
          isChildItem: true,
          classItem: `nav-sub-none-child`,
          isShow: isHavePermissionRole(
            [
              ROLES_FOR_APP.CAMPAIGN_CREATE,
              ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT,
              ROLES_FOR_APP.CAMPAIGN_LIST,
              ROLES_FOR_APP.NEWS_LIST,
            ],
            userPermissions
          ),
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
          stateVariables: true,
          childItems: [],
        },
        {
          id: "campaign-create",
          label: t("Create Campaign"),
          link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_CREATE,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
        },
        {
          id: "create-campaign-sport",
          label: t("Create Campaign Sport"),
          link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_SPORT,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
        },
        {
          id: "create-campaign-sport",
          label: t("Create Campaign Lotto"),
          link: ROUTES.CAMPAIGN_TREND_RANKING_CREATE_CAMPAIGN_LOTTO,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_LOTTO,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
        },
        {
          id: "trend-ranking-news-campaign-list",
          label: t("Campaign"),
          link: ROUTES.CAMPAIGN_TREND_RANKING_LIST_CAMPAIGN,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_LIST,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
        },
        {
          id: "trend-ranking-news-news",
          label: t("News"),
          link: ROUTES.CAMPAIGN_TREND_RANKING_LIST_NEWS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.NEWS_LIST,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "TREND_RANKING_NEWS");
            setIsCurrentState((prev) => "Trend Ranking News");
            updateIconSidebar(e);
          },
        },
        {
          id: "level1.2",
          label: t("Keyword Analyzing News"),
          link: "/#",
          isChildItem: true,
          classItem: `nav-sub-none-child`,
          isShow: isHavePermissionRole(
            [
              ROLES_FOR_APP.CAMPAIGN_CREATE,
              ROLES_FOR_APP.CAMPAIGN_SET_CAMPAIGN_SPORT,
              ROLES_FOR_APP.CAMPAIGN_LIST,
              ROLES_FOR_APP.NEWS_LIST,
            ],
            userPermissions
          ),
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
          stateVariables: true,
          childItems: [],
        },
        {
          id: "create-campaign-popular",
          label: t("Create Campaign (Popular Keyword)"),
          link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_POPULAR,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_CREATE,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
        },
        {
          id: "create-campaign-manual",
          label: t("Create Campaign (Manual Keyword)"),
          link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_CREATE_CAMPAIGN_MANUAL,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_CREATE,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
        },
        {
          id: "create-campaign-flowed-keyword",
          label: t("Create Campaign (Core Keyword) Menu"),
          link: ROUTES.CAMPAIGN_FLOWED_KEYWORD_CREATE_CAMPAIGN,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_CREATE,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
        },
        {
          id: "keyword-analyzing-news-campaign-list",
          label: t("Campaign"),
          link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_CAMPAIGN,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_LIST,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
        },
        {
          id: "keyword-analyzing-news-news",
          label: t("News"),
          link: ROUTES.CAMPAIGN_KEYWORD_ANALYZING_LIST_NEWS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.NEWS_LIST,
            userPermissions
          ),
          isChildItem: false,
          stateVariables: true,
          classItem: "nav-sub-child",
          click: function (e: any) {
            e.preventDefault();
            setMenuActive((prev) => "KEYWORD_ANALYZING__NEWS");
            setIsCurrentState((prev) => "Keyword Analyzing News");
            updateIconSidebar(e);
          },
        },
      ],
    },
    {
      id: "multilevel3",
      label: t("Template"),
      icon: <FeatherIcon icon="layers" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "TEMPLATE");
        setIsCurrentState((prev) => "Template");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "TEMPLATE",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.TEMPLATE_TITLE_LIST,
          ROLES_FOR_APP.TEMPLATE_BODY_LIST,
          ROLES_FOR_APP.VARIABLE_DEFINITION_LIST,
          ROLES_FOR_APP.INFO_GRAPHIC_LIST,
        ],
        userPermissions
      ),
      subItems: [
        {
          id: "template-news-title",
          label: t("Template News Title"),
          link: ROUTES.TEMPLATE_TITLE,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.TEMPLATE_TITLE_LIST,
            userPermissions
          ),
        },
        {
          id: "template-news-content",
          label: t("Template News Content"),
          link: ROUTES.TEMPLATE_CONTENT,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.TEMPLATE_BODY_LIST,
            userPermissions
          ),
        },
        {
          id: "template-info-graphic",
          label: t("Info-Graphic"),
          link: ROUTES.TEMPLATE_INFO_GRAPHIC,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.INFO_GRAPHIC_LIST,
            userPermissions
          ),
        },
        // {
        //     id: "image",
        //     label: t('Image'),
        //     link: "/template/image",
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.INFO_GRAPHIC_LIST, userPermissions),
        // },
        // {
        //     id: "template-variable-definition",
        //     label: t('Variable Definition'),
        //     link: "/template/variable-definition",
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.VARIABLE_DEFINITION_LIST, userPermissions),
        // },
      ],
    },
    {
      id: "multilevel5",
      label: t("Statistics"),
      icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "STATISTICS");
        setIsCurrentState((prev) => "Statistics");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "STATISTICS",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.MEDIA_LOG_LIST,
          ROLES_FOR_APP.VISITOR_STATISTICS_LIST,
          ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
          ROLES_FOR_APP.CATEGORY_STATISTICS_LIST,
          ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST,
          ROLES_FOR_APP.NEWS_STATISTICS_LIST,
          ROLES_FOR_APP.KEYWORD_INFLOW_LIST,
          ROLES_FOR_APP.KEYWORD_STATISTICS_LIST,
          ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST,
        ],
        userPermissions
      ),
      subItems: [
        // {
        //     id: "statistics",
        //     label: t('News Log'),
        //     link: "/statistics/news-log",
        //     // click: function (e: any) {
        //     //     e.preventDefault();
        //     //     // setIsLevel1(!isLevel1);
        //     // },
        //     // stateVariables: isLevel1,
        // },
        {
          id: "media-log",
          label: t("Media Log"),
          link: ROUTES.STATISTICS_MEDIA_LOG,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.MEDIA_LOG_LIST,
            userPermissions
          ),
        },
        {
          id: "revenue",
          label: t("Media Revenue Log"),
          link: ROUTES.STATISTICS_REVENUE_LOG,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.MEDIA_LIST,
            userPermissions
          ),
        },
        {
          id: "visitor-statistics",
          label: t("Visitor Statistics"),
          link: ROUTES.STATISTICS_VISITOR_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.VISITOR_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "media-statistics",
          label: t("Media Statistics"),
          link: ROUTES.STATISTICS_MEDIA_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.MEDIA_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "category-statistics",
          label: t("Category Statistics"),
          link: ROUTES.STATISTICS_CATEGORY_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CATEGORY_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "campaign-statistics",
          label: t("Campaign Statistics"),
          link: ROUTES.STATISTICS_CAMPAIGN_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "news-statistics",
          label: t("News Statistics"),
          link: ROUTES.STATISTICS_NEWS_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.NEWS_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "keyword-inflow",
          label: t("Keyword Inflow"),
          link: ROUTES.STATISTICS_KEYWORD_INFLOW,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.KEYWORD_INFLOW_LIST,
            userPermissions
          ),
        },
        {
          id: "keyword-statistics",
          label: t("Keyword Statistics"),
          link: ROUTES.STATISTICS_KEYWORD_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.KEYWORD_STATISTICS_LIST,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel6",
      label: t("User"),
      icon: <FeatherIcon icon="users" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "USER");
        setIsCurrentState((prev) => "User");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "USER",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.USER_LIST, ROLES_FOR_APP.ROLE_LIST],
        userPermissions
      ),
      subItems: [
        {
          id: "user",
          label: t("User"),
          link: ROUTES.USER_LIST,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.USER_LIST,
            userPermissions
          ),
        },
        {
          id: "role-list",
          label: t("Role"),
          link: ROUTES.USER_ROLE,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.ROLE_LIST,
            userPermissions
          ),
        },
        {
          id: "admin-activity-logs",
          label: t("Admin Activity Logs"),
          link: ROUTES.ADMIN_ACTIVITY_LOGS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.USER_UPDATE,
            userPermissions
          ),
        },
      ],
    },
    {
      id: "multilevel9",
      label: t("Lab"),
      icon: (
        <i
          className="ri ri-flask-line fs-20"
          style={{ transform: "translateX(-2px)" }}
        />
      ),
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "LAB");
        setIsCurrentState((prev) => "Lab");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "LAB",
      isShow: isHavePermissionRole(
        [
          ROLES_FOR_APP.CATEGORY_STATISTICS_LIST,
          ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST,
          ROLES_FOR_APP.NEWS_STATISTICS_LIST,
        ],
        userPermissions
      ),
      subItems: [
        {
          id: "lab-category-statistics",
          label: t("Category Statistics"),
          link: ROUTES.LAB_CATEGORY_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CATEGORY_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "lab-campaign-statistics",
          label: t("Campaign Statistics"),
          link: ROUTES.LAB_CAMPAIGN_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.CAMPAIGN_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "lab-news-statistics",
          label: t("News Statistics"),
          link: ROUTES.LAB_NEWS_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.NEWS_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "media-revenue-statistics",
          label: t("Media Revenue Statistics"),
          link: ROUTES.STATISTICS_MEDIA_REVENUE_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.VISITOR_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "revenue-statistics",
          label: t("Revenue Statistics"),
          link: ROUTES.STATISTICS_REVENUE_STATISTICS,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.VISITOR_STATISTICS_LIST,
            userPermissions
          ),
        },
        {
          id: "media-ranking",
          label: t("Media Ranking"),
          link: ROUTES.STATISTICS_MEDIA_RANKING,
          isShow: isHavePermissionRole(
            ROLES_FOR_APP.MEDIA_RANKING_LIST,
            userPermissions
          ),
        },

        // {
        //     id: "core-keyword-analysis",
        //     label: t('Create Campaign (Core Keyword) Menu'),
        //     link: ROUTES.STATISTICS_FLOWED_KEYWORD_ANALYSIS,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST, userPermissions),
        // },
        // {
        //     id: "keyword-analysis",
        //     label: t('Keyword Analysis'),
        //     link: ROUTES.STATISTICS_KEYWORD_ANALYSIS,
        //     isShow: isHavePermissionRole(ROLES_FOR_APP.KEYWORD_ANALYSIS_LIST, userPermissions),
        // },
      ],
    },
    {
      id: "multilevel12",
      label: t("Configuration"),
      icon: <FeatherIcon icon="settings" className="icon-dual" />,
      link: ROUTES.CONFIGURATION,
      click: function (e: any) {
        setMenuActive((prev) => "CONFIGURATION");
        setIsCurrentState((prev) => "Configuration");
        updateIconSidebar(e);
      },
      stateVariables: menuActive === "CONFIGURATION",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.CONFIGURATION_UPDATE],
        userPermissions
      ),
    },
    {
      id: "multilevel10",
      label: t("MessageHub"),
      icon: (
        <FeatherIcon
          icon="send"
          className="icon-dual"
          style={{
            transform: "rotate(45deg)",
            marginLeft: "-3px",
            marginRight: "13px",
          }}
        />
      ),
      link: redirectMessageHub(),
      // click: function (e: any) {
      //     e.preventDefault();
      //     const url = redirectMessageHub();
      //     window.open(url, "_blank");
      // },
      targetLink: true,
      stateVariables: menuActive === "MessageHub",
      isShow: isHavePermissionRole(
        [ROLES_FOR_APP.NEWS_STATISTICS_LIST],
        userPermissions
      ),
    },
    {
      id: "multilevel13",
      label: t("articles_management.articles_management"),
      icon: <FeatherIcon icon="file-text" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "ARTICLES_MANAGEMENT");
        setIsCurrentState((prev) => "Articles Management");
        updateIconSidebar(e);
      },
      stateVariables:
        menuActive === "ARTICLES_MANAGEMENT" ||
        menuActive === "ARTICLES_MANAGEMENT",
      isShow: isHavePermissionRole(ROLES_FOR_APP.ARTICLE_MANAGEMENT, userPermissions),
      subItems: [
        {
          id: "article_statistics",
          label: t("articles_management.articles_statistics"),
          link: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_STATISTICS,
          // isShow: isHavePermissionRole(
          //     ROLES_FOR_APP.MEDIA_LIST,
          //     userPermissions
          // ),
          // isChildItem: true,
          isShow: true,
          // click: function (e: any) {
          //     e.preventDefault();
          //     setMenuActive((prev) => "MEDIA");
          //     setIsCurrentState((prev) => "Media");
          //     updateIconSidebar(e);
          // },
          stateVariables: true,
        },
        {
          id: "revenue",
          label: t("articles_management.articles_list"),
          link: ROUTES.ARTICLES_MANAGEMENT.ARTICLE_LIST,
          // isShow: isHavePermissionRole(
          //     ROLES_FOR_APP.NOTIFICATION_LIST,
          //     userPermissions
          // ),
          // isChildItem: true,
          isShow: true,
          // click: function (e: any) {
          //     e.preventDefault();
          //     setMenuActive((prev) => "MEDIA");
          //     setIsCurrentState((prev) => "Media");
          //     updateIconSidebar(e);
          // },
          // stateVariables: true,
        },
      ],
    },
    {
      id: "inquiry-management",
      label: t("inquiry.menu_title"),
      click: function (e: any) {
        setMenuActive((prev) => "INQUIRY");
        setIsCurrentState((prev) => "inquiry");
        updateIconSidebar(e);
      },
      icon: (
        <FeatherIcon
          icon="inbox"
          className="icon-dual"
        />
      ),
      link: ROUTES.INQUIRY_MANAGEMENT.INQUIRY,
      stateVariables: menuActive === "INQUIRY",
      isShow: isHavePermissionRole(ROLES_FOR_APP.CUSTOMER_INQUIRY_MANAGEMENT, userPermissions),
      classItem: "hidden-after",
      subItems: [
          {
            id: "inquiry_home_page",
            link: ROUTES.INQUIRY_MANAGEMENT.INQUIRY_HOMEPAGE,
            isShow: false,
            stateVariables: true,
          }
        ],
    },
    {
      id: "multilevel14",
      label: "CMS Hub",
      icon: <FeatherIcon icon="bar-chart-2" className="icon-dual" />,
      link: "/#",
      click: function (e: any) {
        e.preventDefault();
        setMenuActive((prev) => "CMS_HUB_MANAGEMENT");
        setIsCurrentState((prev) => "CMS Hub Management");
        updateIconSidebar(e);
      },
      stateVariables:
        menuActive === "CMS_HUB_MANAGEMENT" ||
        menuActive === "CMS_HUB_MANAGEMENT",
      isShow: isHavePermissionRole(ROLES_FOR_APP.CMS_MANAGEMENT, userPermissions),
      subItems: [
        {
          id: "statistics",
          label: t("cms_statistics.statistics"),
          link: ROUTES.CMS_HUB_STATISTICS,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "CMS_HUB_MEDIA_MANAGEMENT",
          label: t("media.Management"),
          link: ROUTES.CMS_HUB_MEDIA_MANAGEMENT,
          isShow: true,
          stateVariables: true,
        },
        {
          id: "CMS_HUB_MEMBERSHIP",
          label: t("membership.membership_management"),
          link: ROUTES.CMS_HUB_MEMBERSHIP,
          isShow: true,
        },
      ],
    },
      {
        id: "account-management",
        label: t("account.menu_title"),
        click: function (e: any) {
          setMenuActive((prev) => "ACCOUNT");
          setIsCurrentState((prev) => "account");
          updateIconSidebar(e);
        },
        icon: (
          <FeatherIcon
            icon="user"
            className="icon-dual"
          />
        ),
        link: ROUTES.ACCOUNT,
        stateVariables: menuActive === "account",
        isShow: isSuperAdmin(),
        classItem: "hidden-after",
        subItems: [
            {
              id: "account_create",
              link: ROUTES.ACCOUNT_CREATE,
              isShow: false,
              stateVariables: true,
            },
            {
              id: "account_update",
              link: ROUTES.ACCOUNT_UPDATE,
              isShow: false,
              stateVariables: true,
            }
          ],
      },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
