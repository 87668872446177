import i18next from "i18next";
import moment from "moment";

export const catchAsync = async (
  p: any,
  setLoading?: (loading: boolean) => void
) => {
  const isPromise = (p: any) =>
    typeof p === "object" && typeof p.then === "function";

  if (!isPromise(p)) {
    throw new Error("Require promise");
  }

  if (setLoading) setLoading(true);
  try {
    const result = await p;
    if (setLoading) setLoading(false);
    return [null, result];
  } catch (err) {
    if (setLoading) setLoading(false);
    return [err];
  }
};

export function formatNumber(number: number | string) {
  if (!number) {
    return number;
  }
  return new Intl.NumberFormat("en-US").format(
    typeof number === "string" ? parseInt(number) : number
  );
}

export const formatWithKoreanAmPm = (date: any) => {
  if (!date) {
    return "-";
  }
  const formattedDate = moment(date).format("YYYY.MM.DD Ah:mm");
  return formattedDate
    .replace("AM", i18next.t("cms_media.morning"))
    .replace("PM", i18next.t("cms_media.afternoon"));
};

export function encodeFileName(file: any) {
  return new File([file], encodeURIComponent(file.name).toString(), {
    type: file.type,
    lastModified: file.lastModified,
  });
}
